import { useMediaQuery } from "~/hooks/useMediaQuery";
import Modal from "../ui/Modal";
import { useEffect, useState } from "react";
import FlexContainer from "../ui/FlexContainer";
import ProgressBar from "../ui/ProgressBar";
import { useTranslation } from "react-i18next";
import { steps } from "./steps";
import * as S from "./styles";
import Button from "../ui/Button";
import { triggerShowPlansModal } from "~/helpers/customEvents";
import { useAccount } from "~/hooks/useAccount";
import { IOnboardingChoices } from "~/types/account";
import { editProfile } from "~/api/account/requests";
import handleErrors from "~/helpers/handleErrors";

interface Props {
  isShowing: boolean;
  onEnd?: () => void;
}

export const Onboarding = ({ isShowing }: Props) => {
  const { t } = useTranslation();
  const isAboveDesktop = useMediaQuery("desktop");
  const [stepIndex, setStepIndex] = useState(0);
  const { account, refetch } = useAccount();
  const [onboardingChoices, setOnboardingChoices] = useState<
    IOnboardingChoices | undefined
  >(undefined);

  useEffect(() => {
    if (account) {
      setOnboardingChoices(account.onboarding_choices);
    }
  }, [account]);

  if (steps.length === 0 || !account) {
    return null;
  }

  const step = steps[stepIndex];
  const isLastStep = stepIndex === steps.length - 1;
  const isFirstStep = stepIndex === 0;

  const handleBack = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  const handleContinue = async () => {
    if (!isLastStep) {
      setStepIndex(stepIndex + 1);
      return;
    }

    const toastMessages = {
      loading: t("onboarding.loading"),
      success: t("onboarding.success"),
      error: t("onboarding.error"),
    };

    const { errors } = await handleErrors(
      () => editProfile({ onboarding_choices: onboardingChoices }),
      toastMessages
    );

    if (!errors) {
      refetch();
      triggerShowPlansModal();
    }
  };

  return (
    <Modal
      isShowing={isShowing}
      minWidth={isAboveDesktop ? "66%" : "80%"}
      padding="40px">
      <FlexContainer flexDirection="column" gap="20px">
        <FlexContainer
          flexDirection="column"
          gap="0"
          width="100%"
          alignItems="flex-start">
          <S.StepTitle>{t(step.titleKey)}</S.StepTitle>
          <ProgressBar
            progress={((stepIndex + 1) / steps.length) * 100}
            color="primary"
          />
        </FlexContainer>

        <step.component
          onboardingChoices={onboardingChoices}
          setOnboardingChoices={setOnboardingChoices}
        />

        <FlexContainer justifyContent="space-between" width="100%">
          <Button
            bgColor="transparent"
            color="textSecondary"
            padding="16px 24px"
            onClick={handleContinue}>
            {t("onboarding.skip")}
          </Button>

          <FlexContainer>
            {isFirstStep ? null : (
              <Button
                bgColor="transparent"
                color="text"
                padding="16px 24px"
                onClick={handleBack}>
                {t("onboarding.back")}
              </Button>
            )}

            <Button
              bgColor="primary"
              color="white"
              padding="16px 24px"
              onClick={handleContinue}>
              {t("onboarding.continue")}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
