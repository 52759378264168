import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SubscriptionType } from "~/types/billing";
import { usePlans } from "~/api/billing/queries";
import FlexContainer from "~/components/ui/FlexContainer";
import Toggle from "~/components/ui/Toggle";
import PlanCard from "./planCard";
import Loader from "~/components/ui/Loader";
import { useDebounceEffect } from "~/hooks/useDebounceEffect";
import * as S from "./plans.style";
import { useAccount } from "~/hooks/useAccount";

type Props = {
  closeModal?: () => void;
  initialIsAnnual?: boolean;
};

export const Plans = ({ closeModal, initialIsAnnual = false }: Props) => {
  const { t } = useTranslation();
  const { data: plans, isLoading } = usePlans();
  const { account, getAccount } = useAccount();
  const [isAnnual, setIsAnnual] = useState<boolean>(initialIsAnnual);
  const isCurrentPlanAnnual =
    account?.active_subscription?.plan?.interval === SubscriptionType.ANNUAL;

  const activePlans = useMemo(() => {
    return plans?.filter((plan) => plan.status === "AC");
  }, [plans]);

  const filteredPlans = useMemo(() => {
    if (isAnnual)
      return activePlans?.filter(
        (plan) => plan.interval === SubscriptionType.ANNUAL
      );
    return activePlans?.filter(
      (plan) => plan.interval === SubscriptionType.MONTHLY
    );
  }, [activePlans, isAnnual]);

  const biggestMonthsOff = useMemo(() => {
    return plans?.reduce((maxMonths, plan) => {
      return Math.max(maxMonths, plan.months_off);
    }, 0);
  }, [plans]);
  const isInShopifyAppReview = useMemo(
    () => account?.is_annual_shopify || false,
    [account]
  );
  const toggleControl = useCallback(
    () =>
      isCurrentPlanAnnual && !isInShopifyAppReview
        ? setIsAnnual(true)
        : setIsAnnual(!isAnnual),
    [isCurrentPlanAnnual, isAnnual]
  );

  const highestPricePlan = useMemo(
    () =>
      filteredPlans?.reduce(
        (prev, current) =>
          current.cost_per_month > prev.cost_per_month ? current : prev,
        filteredPlans[0]
      ),
    [filteredPlans]
  );

  useEffect(() => {
    if (isCurrentPlanAnnual) setIsAnnual(true);
  }, [isCurrentPlanAnnual]);

  useDebounceEffect(
    () => {
      /* Fix the current plan bug when a user subscribes, and the store is not updated with the required information
    about then plan.
    */
      getAccount();
    },
    [],
    100
  );

  if (isLoading) return <Loader />;

  return (
    <Fragment>
      <S.PageTitle>{t("settings.choose_plan")}</S.PageTitle>

      <FlexContainer
        alignItems="center"
        flexWrap="wrap"
        gap={1}
        data-testid="plan-cycle">
        <S.ToggleContentText className={`${!isAnnual ? "selected" : ""}`}>
          {t("settings.monthly")}
        </S.ToggleContentText>
        <Toggle onChange={toggleControl} value={isAnnual} />
        <S.ToggleContentText className={`${isAnnual ? "selected" : ""}`}>
          {t("settings.annual")}
        </S.ToggleContentText>
        <S.PromotionText>
          <Trans
            i18nKey="settings.up_to_months_off_plans"
            values={{ days: biggestMonthsOff }}
          />
        </S.PromotionText>
      </FlexContainer>

      <S.PlansGrid
        isAnnual={isAnnual}
        columnGap={closeModal === undefined ? 1 : 2}>
        {filteredPlans?.map((plan, index) => {
          let previousPlanName =
            index > 0 ? filteredPlans[index - 1].name : null;
          if (isAnnual && index === 0) {
            previousPlanName =
              activePlans?.filter(
                (plan) => plan.interval === SubscriptionType.MONTHLY
              )[0].name || null;
          }

          return (
            <PlanCard
              account={account}
              key={plan.id}
              plan={plan}
              closeModal={closeModal}
              isHighestPrice={plan.id === highestPricePlan?.id}
              previousPlanName={previousPlanName}
            />
          );
        })}
      </S.PlansGrid>
    </Fragment>
  );
};
