import { sendPost, sendPut } from "../base";
import { sendGet } from "../base";
import { getAPIData } from "../helpers";
import {
  ICancellationInfo,
  ICancelSubscription,
  ISubscriptionShopify,
  ISubscriptionStripe,
} from "./types";

export const getPlans = getAPIData(sendGet("billing/subscriptions/plans/"));

export const shopifySubscription = (params: ISubscriptionShopify) =>
  getAPIData(sendPost("billing/subscriptions/shopify/subscribe/", params));

export const stripeSubscription = (params: ISubscriptionStripe) =>
  getAPIData(sendPost("billing/subscriptions/stripe/subscribe/", params));

export const updateCard = (params: ISubscriptionStripe) =>
  getAPIData(sendPut("billing/subscriptions/stripe/update/", params));

export const cancelSubscription = (params: ICancelSubscription) =>
  getAPIData(sendPost("billing/subscriptions/cancel/", params));

export const pauseSubscription = () =>
  getAPIData(sendPut("billing/subscriptions/pause/"));

export const resumeSubscription = () =>
  getAPIData(sendPut("billing/subscriptions/resume/"));

export const getInvoices = () =>
  getAPIData(sendGet("billing/subscriptions/invoices/"));

export const getProration = (planId?: string) =>
  getAPIData(sendGet(`billing/subscriptions/stripe/proration/${planId}`));

export const stripeIntent = () =>
  getAPIData(sendPost("billing/subscriptions/stripe/intent/"));

export const cancellationInfo = (data: ICancellationInfo) =>
  getAPIData(sendPost("billing/subscriptions/cancellation/", data));