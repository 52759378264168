import styled, { css } from "styled-components";
import { getColor, getSize, responsive } from "~/helpers/style";
import { UIProps } from "~/types/style";

interface IPlanCard extends UIProps {
  primary: boolean;
}

interface IMakeSmaller extends UIProps {
  makeSmaller?: boolean;
}

interface IHighlightable extends IMakeSmaller {
  isHighlighted: boolean | undefined;
}

export const PlanCardContainer = styled.div<IPlanCard>`
  align-items: baseline;
  position: relative;
  flex: 1;
  min-height: 440px;
  max-width: 380px;
  height: auto;
  border: ${({ primary }) => (primary ? "0px" : "1px")} solid
    ${getColor("disabled")};
  border-radius: 18px;

  .separator {
    background-color: ${getColor("disabled")};
    margin-top: ${getSize(2.4)};
  }

  ${responsive("desktop")} {
    min-height: 500px;
  }

  ${({ primary }) =>
    primary &&
    css`
      border-color: transparent;
      box-shadow: 0px 10px 49px 0px #061c3d14;
    `}
`;

export const PlanCardPromotion = styled.div<IHighlightable>`
  position: absolute;
  bottom: calc(100% - 2px);
  left: 50%;
  transform: translateX(-50%);
  padding: ${getSize(0.5)} ${getSize(0.9)};

  z-index: 2;

  font-weight: 500;
  text-transform: uppercase;
  font-size: ${getSize(1.0)};
  line-height: ${getSize(1.4)};

  border-radius: 10px 10px 0 0;

  background-color: ${getColor("primary")};
  color: ${getColor("white")};
`;

export const HeaderContainer = styled.div<IHighlightable>`
  position: relative;
  border-top-right-radius: ${getSize(1.7)};
  border-top-left-radius: ${getSize(1.7)};
  overflow: hidden;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: ${getColor("primary")};
      }
    `};
`;

export const Header = styled.div<IHighlightable>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  padding: ${getSize(2.8)} ${getSize(2.7)} 0 ${getSize(2.7)};

  ${({ makeSmaller }) =>
    makeSmaller &&
    css`
      padding: ${getSize(2.8)} ${getSize(1.7)} 0 ${getSize(1.7)};

      ${responsive("tablet")} {
        padding: ${getSize(2.8)} ${getSize(1.7)} 0 ${getSize(1.7)};
      }

      ${responsive("desktop")} {
        padding: ${getSize(2.8)} ${getSize(2.7)} 0 ${getSize(2.7)};
      }
    `}
`;

interface IMonthsOffContainer extends UIProps {
  isHighlighted: boolean | undefined;
}

export const MonthsOffContainer = styled.div<IMonthsOffContainer>`
  background-color: ${({ isHighlighted }) =>
    getColor(isHighlighted ? "primaryLight" : "greenSecondary")};
  color: ${({ isHighlighted }) =>
    getColor(isHighlighted ? "primary" : "green")};
  width: 100%;
  padding: ${getSize(0.3)};
  position: relative;
  text-align: center;

  font-weight: 600;
  font-size: ${getSize(1.0)};
`;

export const IconAndPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${getSize(1.0)};
`;

export const IconContainer = styled.div<IHighlightable>`
  flex-shrink: 0;
  border-radius: ${getSize(0.7)};
  width: ${getSize(7.0)};
  height: ${getSize(7.0)};
  padding: ${getSize(0.4)};

  background-color: ${getColor("primaryLight")};
  color: ${getColor("primary")};

  display: flex;
  align-items: center;
  justify-content: center;

  ${responsive("tablet")} {
    border-radius: ${getSize(0.5)};
    width: ${({ makeSmaller }) => getSize(makeSmaller ? 3.0 : 4.0)};
    height: ${({ makeSmaller }) => getSize(makeSmaller ? 3.0 : 4.0)};
  }

  ${responsive("laptopL")} {
    width: ${getSize(5.4)};
    height: ${getSize(5.4)};
    padding: ${getSize(1.1)};
  }

  ${responsive("desktop")} {
    border-radius: ${getSize(0.7)};
    width: ${getSize(7.0)};
    height: ${getSize(7.0)};
  }
`;

export const ButtonContainer = styled.div<IHighlightable>`
  text-align: center;
  margin-top: ${getSize(1.4)};

  button {
    border: 1px solid ${getColor("primary")};
    padding: ${getSize(1.1)} ${getSize(2.8)};

    ${({ makeSmaller }) =>
      makeSmaller &&
      css`
        ${responsive("tablet")} {
          padding: ${getSize(1.1)} ${getSize(1.4)};
          font-size: 12px;
        }
      `}

    ${responsive("laptopL")} {
      padding: ${getSize(1.1)} ${getSize(2.8)};
    }
  }
`;

export const PlanCardTitle = styled.h2`
  font-size: ${getSize(2.0)};
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.005em;
  margin: ${getSize(2.4)} 0 0 0;
  position: relative;
`;

interface IPlanCardSubtitle extends UIProps {
  isAnnual: boolean;
}

export const PlanCardSubtitle = styled.h3<IPlanCardSubtitle>`
  font-size: ${getSize(1.2)};
  font-weight: ${({ isAnnual }) => (isAnnual ? 500 : 400)};
  line-height: 20.87px;
  margin: ${getSize(0.7)} 0 0 0;
  color: ${({ isAnnual }) => getColor(isAnnual ? "primary" : "textDisabled")};
  background-color: ${({ isAnnual }) =>
    getColor(isAnnual ? "primaryLight" : "transparent")};

  ${({ isAnnual }) =>
    isAnnual &&
    css`
      padding: ${getSize(0.4)} ${getSize(1.2)};
    `};
`;

export const PlanCardPrice = styled.h3<IHighlightable>`
  font-size: ${getSize(4.2)};
  font-weight: 700;
  line-height: ${getSize(2.4)};
  position: relative;
  display: flex;
  margin: 0;
  align-items: center;
  white-space: nowrap;

  color: ${getColor("primary")};

  ${responsive("tablet")} {
    font-size: ${({ makeSmaller }) => getSize(makeSmaller ? 1.6 : 2.8)};
    line-height: ${getSize(1.9)};
  }

  ${responsive("laptopL")} {
    font-size: ${getSize(3.3)};
  }

  ${responsive("desktop")} {
    font-size: ${getSize(3.6)};
    line-height: ${getSize(2.4)};
  }
`;

export const DiscountText = styled.span`
  width: max-content;
  color: ${getColor("textSecondary")};
  opacity: 0.5;
  font-size: ${getSize(2.0)};
  font-weight: 400;
  line-height: 24.2px;
  letter-spacing: 0.0025em;
  text-decoration: line-through;
  margin-right: ${getSize(0.6)};
`;

export const MonthlyValue = styled.span<IMakeSmaller>`
  color: ${getColor("textDisabled")};
  font-size: ${getSize(1.4)};
  font-weight: 500;

  margin-top: ${getSize(1.0)};
  line-height: 14px;

  ${({ makeSmaller }) =>
    makeSmaller &&
    css`
      margin-top: ${getSize(2.0)};
      line-height: 14px;

      ${responsive("tablet")} {
        font-size: ${getSize(1.0)};
        margin-top: ${getSize(0.4)};
      }

      ${responsive("laptopL")} {
        margin-top: ${getSize(1.4)};
        font-size: ${getSize(1.4)};
        line-height: 14px;
      }
    `}
`;

export const DecimalValue = styled.span<IMakeSmaller>`
  font-size: ${getSize(1.8)};
  margin-top: ${getSize(1.0)};

  ${({ makeSmaller }) =>
    makeSmaller &&
    css`
      font-size: ${getSize(1.8)};
      margin-top: ${getSize(2.0)};

      ${responsive("tablet")} {
        font-size: ${getSize(1.0)};
        margin-top: ${getSize(0.4)};
      }

      ${responsive("laptopL")} {
        margin-top: ${getSize(1.4)};
        font-size: ${getSize(1.4)};
        line-height: 14px;
      }
    `}
`;

export const PlanCardList = styled.div<IMakeSmaller>`
  display: flex;
  flex-direction: column;
  font-size: ${getSize(1.6)};
  gap: ${getSize(1)};
  margin-top: ${getSize(2.4)};
  padding: 0 ${getSize(2.8)} ${getSize(3.3)};

  ${({ makeSmaller }) =>
    makeSmaller &&
    css`
      ${responsive("tablet")} {
        padding: 0 ${getSize(1.4)} ${getSize(3.3)};
      }

      ${responsive("desktop")} {
        padding: 0 ${getSize(2.8)} ${getSize(3.3)};
      }
    `}
`;

interface IPlanCardItem extends UIProps {
  disabled: boolean;
}

export const PlanCardItem = styled.div<IPlanCardItem>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${getSize(1.2)};

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

export const PlanCardItemIconContainer = styled.div<IMakeSmaller>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7f5e8;
  border-radius: 100%;
  width: ${getSize(2.0)};
  height: ${getSize(2.0)};
  padding: ${getSize(0.4)};

  & > div > svg {
    max-width: 100%;
  }

  ${({ makeSmaller }) =>
    makeSmaller &&
    css`
      ${responsive("tablet")} {
        width: ${getSize(1.6)};
        height: ${getSize(1.6)};
      }

      ${responsive("desktop")} {
        width: ${getSize(2.0)};
        height: ${getSize(2.0)};
      }
    `}
`;

export const FeatureText = styled.div<IMakeSmaller>`
  font-size: ${getSize(1.3)};
  line-height: ${getSize(1.4)};
  width: calc(100% - ${getSize(3.6)});

  ${({ makeSmaller }) =>
    makeSmaller &&
    css`
      ${responsive("tablet")} {
        font-size: ${getSize(1.2)};
      }

      ${responsive("desktop")} {
        font-size: ${getSize(1.3)};
      }
    `}
`;

export const EverythingPlusText = styled.div`
  font-size: ${getSize(1.3)};
  line-height: ${getSize(1.8)};
  font-weight: 500;

  margin-bottom: ${getSize(0.6)};
`;
