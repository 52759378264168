import { useCallback, useContext, useEffect } from "react";
import { getUser } from "~/api/account/requests";
import { AccountContext } from "~/contexts/Account";
import { IAccount } from "~/types/account";
import { Userpilot } from "userpilot";
import _debounce from "lodash/debounce";
import { useIntercom } from "react-use-intercom";
import { sentryProject, intercomKey } from "~/helpers/environment";
import { DISABLE_PAYMENTS } from "~/helpers/plans";

export const useAccount = () => {
  const { account, setAccount } = useContext(AccountContext);
  const { boot } = useIntercom();

  const setAccountWithThirdParties = (acc: IAccount | null) => {
    setAccount(acc);
    if (acc) {
      Userpilot.identify(acc.id.toString(), {
        email: acc.email,
        created_at: acc.created_at,
        name: acc.name,
        payment_provider: acc.payment_provider,
        signup_origin: acc.signup_origin,
      });
      if (intercomKey && (acc?.active_subscription || DISABLE_PAYMENTS)) {
        boot({
          email: acc.email,
          userId: sentryProject + "_" + acc.id.toString(),
          name: acc.name,
          customAttributes: {
            app: sentryProject,
          },
        });
      }
    }
  };

  const getAccount = async () => {
    const profile = (await getUser()) as IAccount;
    setAccountWithThirdParties(profile);
    return profile;
  };
  const debouncedGetAccount = useCallback(_debounce(getAccount, 200), []);

  useEffect(() => {
    if (!account) debouncedGetAccount();
  }, []);

  return {
    account,
    setAccount: setAccountWithThirdParties,
    getAccount,
    refetch: getAccount,
  };
};
