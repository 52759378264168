import { ILimit, IPlan, Limits } from "~/types/billing";

export const DISABLE_PAYMENTS = false;

export const isFeatureEnabledOnPlan = (
  limitType: Limits,
  plan: IPlan | undefined
) => {
  if (DISABLE_PAYMENTS) return true;

  const limits: ILimit | Record<any, any> = plan?.limits || {};

  if (limitType === Limits.PAID_PLAN) {
    return !!plan;
  }

  if (!limits) return false;

  return !!limits[limitType];
};
